import { useEffect, useRef } from 'react';

/**
 * @template T
 * @param {T} cb
 * @returns {T}
 */
const useCallbackReference = (cb) => {
  var ref = useRef(cb);

  useEffect(() => {
    ref.current = cb;
  }, [cb]);

  return (...args) => {
    if (ref.current) {
      return ref.current.apply(ref, args);
    }
  };
};

export default useCallbackReference;
