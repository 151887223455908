import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

const Portal = ({ children }) => {
  const [el, setEl] = useState(null);

  useEffect(() => {
    setEl(document.createElement('div'));
  }, []);

  useEffect(() => {
    if (el) {
      document.body.appendChild(el);
      return () => document.body.removeChild(el);
    }
  }, [el]);

  // Check that el is not null before using ReactDOM.createPortal
  if (el) {
    return ReactDOM.createPortal(children, el);
  } else {
    return null;
  }
};

export default Portal;
