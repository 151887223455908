import React from 'react';
import Layout from '../components/layout/layout';
import SEO from '../components/meta/seo';
import LoginTemplate from '../components/monetization/customer-portal/template';

const ManageSubscription = () => {
  return (
    <Layout isDark isMinimal>
      <SEO
        title="Manage Subscription"
        description="Edit, cancel or pause your subscription at any moment"
      />
      <LoginTemplate />
    </Layout>
  );
};

export default ManageSubscription;
