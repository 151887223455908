import React from 'react';
import PropTypes from 'prop-types';

import EmailIcon from '../../../assets/onboarding/email.svg';

const EmailInput = ({ hasError, value, onChange, onFocus, onBlur }) => {
  return (
    <div
      className={`flex section-dark bg-black rounded-lg p-4 items-center space-x-2 ${
        hasError ? 'ring-red-plain ring-2' : ''
      }`}
    >
      <EmailIcon role="img" aria-label="Email" />
      <input
        type="email"
        className="R16-new bg-transparent placeholder-contrast-low contrast-high w-full"
        placeholder="Email address"
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
  );
};

EmailInput.propTypes = {
  hasError: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

export default EmailInput;
