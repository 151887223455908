import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';

import GrayCrossIcon from '../../../assets/onboarding/cross--gray.svg';

const Modal = ({
  onDismiss,
  children,
  transitionStatus,
  onTransitionComplete,
}) => {
  const wrapperRef = useRef();

  const getTimeline = () => {
    const tl = gsap.timeline({ paused: true });
    tl.fromTo(
      wrapperRef.current,
      { scale: 0 },
      { scale: 1, ease: 'back.out(2)' }
    );
    return tl;
  };

  useEffect(() => {
    if (transitionStatus != 'waiting') {
      let tl = getTimeline();
      if (transitionStatus == 'entering') {
        tl = tl.play();
      } else if (transitionStatus == 'exiting') {
        tl = tl.seek(1).reverse();
      }
      tl.then(onTransitionComplete);
      return () => tl.kill;
    }
  }, [transitionStatus]);

  return transitionStatus != 'waiting' ? (
    <div
      role="dialog"
      tabIndex="-1"
      className="card card-dark card-outline max-w-half-column min-h-0 duration-0 transform scale-0"
      ref={wrapperRef}
    >
      <button
        type="button"
        className="absolute p-2 right-4 md:right-6 top-4 md:top-6"
        onClick={onDismiss}
      >
        <GrayCrossIcon role="img" alt="Close icon" />
      </button>
      {children}
    </div>
  ) : null;
};

Modal.propTypes = {
  onDismiss: PropTypes.func,
  children: PropTypes.node,
  transitionStatus: PropTypes.oneOf(['waiting', 'entering', 'exiting']),
  onTransitionComplete: PropTypes.func,
};

export default Modal;
