import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EmailInput from '../ui/emailInput';

import LoadingIcon from '../../../assets/onboarding/loading.svg';

const LoginForm = ({ isLoading, onSubmit }) => {
  const [email, setEmail] = useState('');
  const [valid, setValid] = useState(false);
  const [hasError, setHasError] = useState('');

  /**
   * @param {import('react').SyntheticEvent<
   *   HTMLInputElement,
   *   import('react').ChangeEvent<HTMLInputElement>
   * >} e
   */
  const onEmailChange = (e) => {
    /** @type {HTMLInputElement} */
    const target = e.target;
    setValid(target.validity.valid && target.value);
    setEmail(target.value);
  };

  const onEmailFocus = () => {
    setHasError(false);
  };

  const onEmailBlur = () => {
    setHasError(!valid);
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (valid) {
      onSubmit(email);
    }
  };

  return (
    <form className="space-y-6" noValidate onSubmit={onFormSubmit}>
      <EmailInput
        value={email}
        hasError={hasError}
        onChange={onEmailChange}
        onFocus={onEmailFocus}
        onBlur={onEmailBlur}
      />
      <button
        className="btn btn--blue btn--full relative"
        disabled={!valid || isLoading}
      >
        <span
          className={`transition-opacity duration-300 ${
            isLoading ? 'opacity-0' : 'delay-300 opacity-100'
          }`}
        >
          Send magic link
        </span>
        <LoadingIcon
          className={`absolute center transition-opacity duration-300 ${
            isLoading ? 'delay-300 opacity-100' : 'opacity-0'
          }`}
        />
      </button>
    </form>
  );
};

LoginForm.propTypes = {
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default LoginForm;
