import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useCallbackReference from '../../hooks/use-callback-reference';

const EntryExitScheduler = ({
  children,
  onEntryStarted,
  onEntryComplete,
  onExitStarted,
  onExitComplete,
}) => {
  const [state, setState] = useState({ oldEntry: null, newEntry: null });
  const callOnEntryComplete = useCallbackReference(onEntryComplete);
  const callOnExitComplete = useCallbackReference(onExitComplete);

  const child =
    React.Children.toArray(children).find((child) => !!child) || null;

  if (child?.key != state.newEntry?.key) {
    // if (state.oldEntry) {
    //   throw new Error('Only one element can be exiting at any given time');
    // }
    setState({
      newEntry: child,
      oldEntry: state.newEntry,
    });
  }

  useEffect(() => {
    if (state.oldEntry && typeof onExitStarted == 'function') {
      onExitStarted();
    } else if (state.newEntry && typeof onEntryStarted == 'function') {
      onEntryStarted();
    }
  }, [state]);

  return (
    <>
      {state.oldEntry &&
        React.cloneElement(state.oldEntry, {
          transitionStatus: 'exiting',
          onTransitionComplete: () => {
            setState((state) => ({ ...state, oldEntry: null }));
            callOnExitComplete();
          },
        })}
      {/* The new entry is always the child, but the child is not always the new entry */}
      {/* Or rather: the child might have the same key across renders but present different content */}
      {child &&
        React.cloneElement(child, {
          transitionStatus: state.oldEntry ? 'waiting' : 'entering',
          onTransitionComplete: callOnEntryComplete,
        })}
    </>
  );
};

EntryExitScheduler.propTypes = {
  children: PropTypes.node,
  onEntryStarted: PropTypes.func,
  onEntryComplete: PropTypes.func,
  onExitStarted: PropTypes.func,
  onExitComplete: PropTypes.func,
};

export default EntryExitScheduler;
